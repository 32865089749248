<template>
<div>
	<creatsuccess :msg="showtype" @recovery="recovery">
		<div class="editgiftdetails" v-loading="detailLoading">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>基本信息</span>
					</div>
					<div class="content">
						<el-form-item label="活动名称:" prop="Name">
							<el-input type="text" v-model="ruleForm.Name" :disabled='checkIndex == 3' placeholder='最多输入20个汉字' maxlength='20'
							style="width:300px;"></el-input>
						</el-form-item>
						<el-form-item label="活动时间:" required>
							<el-form-item prop="StartTime" class="el-form-date-pick">
								<el-date-picker v-model="ruleForm.StartTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
								default-time="00:00:00" type="datetime" placeholder="开始时间" :picker-options='maxOptions' :disabled="(currentEditType==1&&currentEditStatus=='进行中') || checkIndex == 3">
								</el-date-picker>
							</el-form-item>
							<span style="margin:0 10px;">~</span>
							<el-form-item prop="EndTime" class="el-form-date-pick">
								<el-date-picker v-model="ruleForm.EndTime" :disabled='checkIndex == 3' format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
								type="datetime" placeholder="结束时间" :picker-options='minOptions' default-time="23:59:59">
								</el-date-picker>
							</el-form-item>
						</el-form-item>
						<el-form-item label="活动标签:" prop="ActivityFlag">
							<el-input type="text" v-model="ruleForm.ActivityFlag" :disabled='checkIndex == 3' style="width:300px;"></el-input>
						</el-form-item>
						<div style="color: #999;font-size: 14px;padding-left: 150px;margin-bottom: 15px;">活动期间显示在商品详情页、商品分享图片、商品列表</div>


						<el-form-item label="助力人数:" prop="HelpNumber">
							<el-input type="text" v-model="ruleForm.HelpNumber" :disabled='checkIndex == 3 || (currentEditType==1&&currentEditStatus=="进行中")'
							@input='onlyclustNum(ruleForm.HelpNumber,1)' style="width: 150px;"></el-input><span style="margin-left: 4px;">人</span>
						</el-form-item>
						<div style="color: #999;font-size: 14px;padding-left: 150px;margin-bottom: 15px;">助力成功，需要参与助力的人数;助力人数需大于0人</div>


						<el-form-item label="本场活动每个人可以帮他人助力的次数:" prop="HelpLimitNumber" label-width="270px" style="margin-left:60px;">
							<el-input type="text" v-model="ruleForm.HelpLimitNumber" maxlength='4' @input='onlyclustNum(ruleForm.HelpLimitNumber,4)' style="width: 150px;"></el-input><span
							style="margin-left: 4px;">次</span>
						</el-form-item>
						<div style="color: #999;font-size: 14px;padding-left: 330px;margin-bottom: 15px;">填写0,表示无限制</div>
						<el-form-item label="助力有效期:" prop="HelpEffectiveTime">
							<el-input type="text" v-model="ruleForm.HelpEffectiveTime" :disabled='checkIndex == 3 || (currentEditType==1&&currentEditStatus=="进行中")'
							@input='onlyclustNum(ruleForm.HelpEffectiveTime,2)' style="width: 150px;"></el-input><span style="margin-left: 4px;">小时</span>
						</el-form-item>
						<div style="color: #999;font-size: 14px;padding-left: 150px;margin-bottom: 15px;">设置助力有效期,在该时间内未助力成功的活动,助力失败,助力有效时间必须是大于0的整数</div>
						<el-form-item label="领取有效期:" prop="ReceiveEffectiveTime">
							<el-input type="text" v-model="ruleForm.ReceiveEffectiveTime" :disabled='checkIndex == 3 || (currentEditType==1&&currentEditStatus=="进行中")'
							@input='onlyclustNum(ruleForm.ReceiveEffectiveTime,3)' style="width: 150px;"></el-input><span style="margin-left: 4px;">天</span>
						</el-form-item>
						<div style="color: #999;font-size: 14px;padding-left: 150px;margin-bottom: 15px;">设置领取有效期,助力成功后需在规定时间内领取完毕,过期无法领取</div>
						<el-form-item label="活动限购" required>
							<div>
								<el-radio v-model="ruleForm.ActivityLimitType" :disabled='checkIndex == 3' :label="0">不限购</el-radio>
							</div>
							<div style="display: flex;align-items: center;margin-bottom:21px;">
								<el-radio v-model="ruleForm.ActivityLimitType" :disabled='checkIndex == 3' :label="1">
									限购
								</el-radio>
								<el-form-item :prop="ruleForm.ActivityLimitType ==1?'ActivityQuota':'nocheck'" :rules='ruleForm.ActivityLimitType==1?rules.ActivityQuota:rules.nocheck'>
									<el-input :disabled="ruleForm.ActivityLimitType==0 || checkIndex == 3" v-model="ruleForm.ActivityQuota" style="width: 100px;margin: 0 5px;"
									onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
									onblur="this.v();if (value==0||value=='-'){value =''}"></el-input>件/人
								</el-form-item>
							</div>
							<div style="color: #909399;margin-top: 10px;">活动期间每个用户可免费领取的数量</div>
						</el-form-item>
						<el-form-item label="商品限购" required>
							<div>
								<el-radio v-model="ruleForm.LimitType" :disabled='checkIndex == 3' :label="0">不限购</el-radio>
							</div>
							<div style="display: flex;align-items: center;margin-bottom:21px;">
								<el-radio v-model="ruleForm.LimitType" :label="1" :disabled='checkIndex == 3'>
									限购
								</el-radio>
								<el-form-item :prop="ruleForm.LimitType ==1?'Quota':'nocheck'" :rules='ruleForm.LimitType==1?rules.Quota:rules.nocheck'>
									<el-input :disabled="ruleForm.LimitType==0|| checkIndex == 3" v-model="ruleForm.Quota" style="width: 100px;margin: 0 5px;"
									onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
									onblur="this.v();if (value==0||value=='-'){value =''}"></el-input>件/人
								</el-form-item>
							</div>
							<div style="color: #909399;margin-top: 10px;">活动期间每个用户每个商品可免费领取的数量</div>
						</el-form-item>
						<el-form-item label="活动商品：" required>
							<div>
								<span :class="{'is-error-button':!ruleForm.ProductList.length}" style="color:#409EFF;cursor: pointer;margin-right:10px"
								@click="handleSelectProDialog" v-if="checkIndex!=3">选择商品</span>
								<el-input v-model="productSearchKey" placeholder="搜索已选商品名称、编码" clearable style="width:300px;"
								@keyup.enter.native="handleFilterSelectes">
									<el-button slot="append" icon="el-icon-search" @click='handleFilterSelectes'></el-button>
								</el-input>
							</div>
							<el-table border :data="paginationProduct" style="width:1000px;margin-top:20px" v-loading="productLoading"
							:row-key="productRowKeys">
								<el-table-column :key="5" label="商品" width="300px">
									<template slot-scope="scope">
										<div class="dialog-name-content">
											<img :src="imgUrl+scope.row.ImgUrl+'@!cut64'" />
											<div class="right">
												<div class="name">
													<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
												</div>
												<span style='color:#F56C6C;'>{{scope.row.Delstatus?'已删除':(!scope.row.IsOpen?'已下架':(scope.row.Stock==0?'已售罄':''))}}</span>
											</div>
										</div>
									</template>
								</el-table-column>
								<el-table-column :key="6" prop="ProductPrice" label="零售价"></el-table-column>
								<el-table-column :key="7" prop="Stock" label="库存"></el-table-column>
								<el-table-column :key="8" label="操作">
									<template slot-scope="scope">
										<el-button v-if="checkIndex !=3" style="color:#F56C6C;" @click="deleteSortPro(scope.row,scope.$index)" type="text"
										size="small">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<el-pagination v-if="proPage.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
							@current-change="handleCurrentChange" :current-page="proPage.current" :page-sizes="[5,10, 20]" :page-size="proPage.size"
							layout="total, sizes, prev, pager, next, jumper" :total="proPage.total">
							</el-pagination>
						</el-form-item>
						<el-form-item label="活动主页头图:" prop="ActivityPageHeadImgUrl">
							<el-upload class="avatar-uploader" :action="imgApi" :show-file-list="false" :on-success="handleAvatarSuccess"
							list-type="picture-card" style="display: inline-block;marin-left:10px;">
								<img v-if="ruleForm.ActivityPageHeadImgUrl" :src="imgUrl + ruleForm.ActivityPageHeadImgUrl" class="upload-avatar"
								style="width: 300px;height: 150px;" />
								<i v-else class="el-icon-plus"></i>
							</el-upload>
						</el-form-item>
						<el-form-item label="活动规则:" style="margin-bottom: 160px;">
							<div style="display: flex;">
								<div style="position: relative;">
									<el-input @input="descInput" :disabled='checkIndex == 3' type="textarea" :rows="6" resize="none" style="width: 500px;"
									v-model="ruleForm.ActivityRule" placeholder="请输入本场活动规则,最多600字" maxlength="500"></el-input>
									<span style='position:absolute;bottom:0px;right:20px;'>{{txtVal}}/600</span>
								</div>
							</div>
						</el-form-item>

						<el-form-item label="活动导航图:" style="margin-bottom: 160px;">
							<div class="img-content" @mouseover.prevent="is_hover = true" @mouseleave.prevent="is_hover = false">
								<div class="mask" v-show="is_hover&&ruleForm.NavigationImgUrl">
									<i class="el-icon-delete bin" @click.prevent="deleteImage()"></i>
								</div>
								<el-upload :action="imgApi" class="avatar-uploader" :show-file-list="false" :on-success="handleImgUrlSuccess"
								list-type="picture-card" style="display: inline-block;">
									<div>
										<img v-if="ruleForm.NavigationImgUrl" :src="imgUrl + ruleForm.NavigationImgUrl" class="upload-avatar" />
										<i v-if="!ruleForm.NavigationImgUrl" class="el-icon-plus"></i>
									</div>
								</el-upload>

							</div>
							<div class="remarks">图片建议尺寸：长：750px；高：150px。
								<el-button type="text" @click='examplesVisible = true'>点击查看示例</el-button>
							</div>
							<div class="link-box">
								<span>链接:</span>
								<div class="link-content">
									<el-dropdown trigger="click" placement="bottom-start" class="link-info" @command="handleCommand($event)">
										<span class='link-btn' v-if="!ruleForm.PageName && (!ruleForm.CommunityGroupCodeTitle)">请选择链接页面</span>
										<span class='link-btn' v-else>{{ruleForm.CommunityGroupCodeTitle||ruleForm.PageName}}</span>
										<span type="text" style='margin-left: 10px;' v-show='!ruleForm.PageName=="" || !ruleForm.CommunityGroupCodeTitle==""'
										class='page-button-error' @click='handleDeletePage()'> X </span>

										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item command="0">选择小程序内页面</el-dropdown-item>
											<el-dropdown-item command="1">进入小程序客服</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div>
							</div>
						</el-form-item>
					</div>
				</el-card>
			</el-form>
			<div class="bottom-save-btn">
				<el-button style="width:240px" @click="canselEdit">取消</el-button>
				<el-button type="primary" style="width:240px" @click="submitForm('ruleForm')" :loading="saveLoading">保存</el-button>
			</div>
			<link-modal :showLiveTab="true" :visible="visibleable" @change="dataChange" @cancel="visibleable=false"></link-modal>

			<el-dialog :visible.sync="examplesVisible" width="400px">
				<img style="width:300px;object-fit:contain;margin-left: 25px;" src='http://cdn.dkycn.cn/images/melyshop/assetIndeximg.png'></img>
			</el-dialog>
			<el-dialog title="选择商品" :visible.sync="selectProDialog" width="1300px" class="dialog">
				<giftdataProduct api="VirtualProductList" :couponData="couponData" :selectedData="ruleForm.ProductList"
				@getSelectList="getSelectList" :visible.sync="selectProDialog" v-if="selectProDialog"></giftdataProduct>
			</el-dialog>
			<el-dialog title="以下商品与其他活动冲突，请重新设置" :visible.sync="conflictGoodsShow" width="1000px" class="dialog data-dialog">
				<div>
					<el-table border :data="conflictGoodsList" style="max-height:450px;overflow: auto;">
						<el-table-column :key="20" label="商品" width="300px">
							<template slot-scope="scope">
								<div class="dialog-name-content">
									<img :src="imgUrl+scope.row.ImgUrl+'@!cut64'" />
									<div class="right">
										<div class="name">
											{{scope.row.ProductName}}
										</div>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column :key="21" prop="Remark" label="冲突信息"></el-table-column>
					</el-table>
				</div>
				<div style="text-align:center;margin-top:20px;">
					<el-button class="button" style="width:200px" @click="conflictGoodsShow=false" type="primary">确定</el-button>
				</div>
			</el-dialog>



			<!-- 示例图 -->
			<el-dialog :visible.sync="wxReplyVisible" width="800px" title="选择小程序客服回复内容" class="reply-dialog">
				<div class="reply-list">
					<div class="item" v-for="(item,index) in wxReplyList" :key="item.Id">
						<el-radio v-model="selectedReply.id" :label="item.Id">{{&nbsp;}}</el-radio>
						<div class="item-content">
							<div class="item-title">{{item.CommunityGroupCodeTitle}}</div>
							<div class="item-text">
								<span class="label">回复文本：</span>
								<span>{{item.CommunityGroupCodeText}}</span>
							</div>
							<div class="item-img">
								<span class="label">回复图片： </span>
								<div class="reply-img-list">
									<img :src="imgUrl+iitem" class="img" v-for="(iitem,iidex) in item.CommunityGroupCodeImgUrl">
								</div>
							</div>
							<span class="item-remark" v-show="item.IsCommunityGroupLockEmployeeFirst">
								若有锁粉店员，优先回复锁粉店员企业微信二维码图片，已开启
							</span>
						</div>
					</div>
				</div>
				<div slot="footer" class="dialog-footer">
					<el-button type="primary" style="width:200px" @click="saveWxReply">确定</el-button>
				</div>
			</el-dialog>
		</div>
	</creatsuccess>
</div>
</template>

<script>
	import config from '@/config/index';
	import apiList from '@/api/other';
	import linkModal from '@/views/components/linkModal';
	import {
		activityHelpReceiveInfo,
		activityHelpReceiveSave
	} from '@/api/TurnTomySelf.js'
	import giftdataProduct from './selectassistGoods'
	import creatsuccess from "@/components/creatsuccess/creatsuccess.vue"
	export default {
		components: {
			giftdataProduct,
			linkModal,
			creatsuccess
		},
		data() {
			var checkno = (rule, value, callback) => {
				return callback();
			}
			var HelpNumberCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入助力人数'));
				} else if (value < 1) {
					return callback(new Error('请填写大于0的助力人数'));
				} else {
					return callback();
				}
			}
			var HelpLimitNumberCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请填写本场活动每个人可以助力的次数'));
				} else if (value < 1 && Number(value) != 0) {
					return callback(new Error('请输入>=0的整数'));
				} else {
					return callback();
				}
			}
			var HelpEffectiveTimeCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入助力有效期'));
				} else if (value < 1) {
					return callback(new Error('请填写大于0的助力有效期'));
				} else {
					return callback();
				}
			}
			var ReceiveEffectiveTimeCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入领取有效期'));
				} else if (value < 1) {
					return callback(new Error('请填写大于0的领取有效期'));
				} else {
					return callback();
				}
			}
			var ActivityQuotaCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入正确的限购数量'));
				} else if (value < 1 || value > 99999) {
					return callback(new Error('请输入正确的限购数量'));
				} else {
					return callback();
				}
			};
			var QuotaCheck = (rule, value, callback) => {
				if (!value && value !== 0) {
					return callback(new Error('请输入正确的限购数量'));
				} else if (value < 1 || value > 99999) {
					return callback(new Error('请输入正确的限购数量'));
				} else {
					return callback();
				}
			};
			return {
				examplesVisible: false,
				wxReplyList: [],
				selectedReply: {
					id: null,
					title: '',
				},
				wxReplyVisible: false,
				visibleable: false,
				editData: {},
				is_hover: false,
				conflictGoodsShow: false,
				conflictGoodsList: [],
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				checkIndex: null,
				txtVal: 0,
				saveLoading: false,
				selectProDialog: false,
				couponData: {},
				productLoading: false,
				proPage: {
					current: 1,
					total: 0,
					size: 5
				},
				paginationProduct: [],
				selectedFilterList: [],
				productSearchKey: '',
				detailLoading: false,
				ruleForm: {
					Name: '',
					StartTime: '',
					EndTime: '',
					ActivityFlag: '',
					HelpNumber: '',
					HelpEffectiveTime: '',
					ReceiveEffectiveTime: '',
					ActivityLimitType: 0,
					ActivityQuota: '',
					LimitType: 0,
					Quota: '',
					ProductList: [],
					ActivityPageHeadImgUrl: '/activityHeadimg.png',
					ActivityRule: '',
					NavigationImgUrl: '',
					LinkType: 0,
					PageName: '',
					PagePath: '',
					MenuType: null,
					CustomerServiceReplyContentId: null,
					CommunityGroupCodeTitle: '',
					OtherData: '',
					HelpLimitNumber: ''
				},
				rules: {
					nocheck: [{
						validator: checkno,
						trigger: 'change'
					}],
					Name: [{
						required: true,
						message: '请输入活动名称',
						trigger: 'blur'
					}],
					StartTime: [{
						required: true,
						message: '请选择开始日期',
						trigger: 'blur'
					}],
					EndTime: [{
						required: true,
						message: '请选择结束日期',
						trigger: 'blur'
					}],
					// ActivityFlag: [{
					// 	required: true,
					// 	message: '请输入活动标签',
					// 	trigger: 'blur'
					// }],
					HelpNumber: [{
						required: true,
						validator: HelpNumberCheck,
						trigger: 'blur'
					}],
					HelpEffectiveTime: [{
						required: true,
						validator: HelpEffectiveTimeCheck,
						trigger: 'blur'
					}],
					ReceiveEffectiveTime: [{
						required: true,
						validator: ReceiveEffectiveTimeCheck,
						trigger: 'blur'
					}],
					ActivityQuota: [{
						required: true,
						validator: ActivityQuotaCheck,
						trigger: 'blur'
					}],
					Quota: [{
						required: true,
						validator: QuotaCheck,
						trigger: 'blur'
					}],
					ActivityPageHeadImgUrl: [{
						required: true,
						message: '请上传活动主页头图',
						trigger: 'blur'
					}],
					HelpLimitNumber: [{
						required: true,
						validator: HelpLimitNumberCheck,
						trigger: 'blur'
					}]

				},
				showtype:{
					issuccess:false,
					id:0,
					type:0,
					suctype:7,
				}
			}

		},
		created() {
			this.currentEditStatus = this.$route.query.status
			this.currentEditType = this.$route.query.type
			this.checkIndex = this.$route.query.check
			this.ruleForm.Id = this.$route.query.Id
			this.getInformation()
		},
		methods: {
			recovery(){
				this.$router.push({path:this.$route.path,query:{
					type:2,
					id:0,
					status:1
				}})
				this.showtype.issuccess = false
				this.ruleForm =  {
					Name: '',
					StartTime: '',
					EndTime: '',
					ActivityFlag: '',
					HelpNumber: '',
					HelpEffectiveTime: '',
					ReceiveEffectiveTime: '',
					ActivityLimitType: 0,
					ActivityQuota: '',
					LimitType: 0,
					Quota: '',
					ProductList: [],
					ActivityPageHeadImgUrl: '/activityHeadimg.png',
					ActivityRule: '',
					NavigationImgUrl: '',
					LinkType: 0,
					PageName: '',
					PagePath: '',
					MenuType: null,
					CustomerServiceReplyContentId: null,
					CommunityGroupCodeTitle: '',
					OtherData: '',
					HelpLimitNumber: ''
				}
				this.paginationProduct = []
			},
			saveWxReply() {
				this.wxReplyList.forEach(t => {
					if (t.Id == this.selectedReply.id) {
						this.ruleForm.CommunityGroupCodeTitle = t.CommunityGroupCodeTitle
						this.ruleForm.PagePath = t.PagePath
					}
				})
				this.ruleForm.CustomerServiceReplyContentId = this.selectedReply.id
				this.ruleForm.PageName = '进入小程序客服'
				this.ruleForm.type = 'connect'

				this.wxReplyVisible = false

			},
			handleDeletePage() {
				this.ruleForm.PageName = ''
				this.ruleForm.PagePath = ''
				this.ruleForm.MenuType = null
				this.ruleForm.CustomerServiceReplyContentId = null
				this.ruleForm.CommunityGroupCodeTitle = ''
				// this.ruleForm.LinkType = 0
			},
			dataChange(record) {
				if (record.type == "classify") {
					this.ruleForm.PageName = record.data.GroupName
					this.ruleForm.MenuType = 1
					this.ruleForm.PagePath = 'pages/productGroup/productGroup?id=' + record.data.Id
				} else if (record.type == "functional") {
					this.ruleForm.PageName = record.data.name
					this.ruleForm.MenuType = 2
					// 全部商品
					if (record.data.id == 1) {
						this.ruleForm.PagePath = 'pages/index/index'
					} else if (record.data.id == 2) {
						this.ruleForm.PagePath = 'pages/allProduct/allProduct'
					} else if (record.data.id == 3) {
						// 个人中心
						this.ruleForm.PagePath = 'pages/personalCenter/mine/mine'
					} else if (record.data.id == 4) {
						// 购物车
						this.ruleForm.linkInfo.PagePath = 'pages/shoppingCart/shoppingCart'
					} else if (record.data.id == 5) {
						// 积分中心
						this.ruleForm.PagePath = 'pages/pointsCenter/pointsCenter'
					} else if (record.data.id == 6) {
						// 每日签到
						this.ruleForm.PagePath = 'pages/userSign/userSign'
					} else if (record.data.id == 7) {
						// 客服
						this.ruleForm.PagePath = 'pages/customerService/customerService'
					} else if (record.data.id == 8) {
						// 订单列表
						this.ruleForm.PagePath = 'pages/order/myOrder/myOrder'
					} else if (record.data.id == 9) {
						// 分销礼包
						this.ruleForm.PagePath = 'pages/invitation/invitationCard/invitationCard'
					} else if (record.data.id == 10) {
						// 成为会员
						this.ruleForm.PagePath = 'pageA/pages/openSuccess/openSuccess'
					} else if (record.data.id == 11) {
						// 助力直播列表
						this.ruleForm.PagePath = 'pageA/pages/live/live'
					} else if (record.data.id == 12) {
						// 扫码购
						this.ruleForm.PagePath = 'pageA/pages/scan/scan'
					} else if (record.data.id == 13) {
						// 充值中心
						this.ruleForm.PagePath = 'pageA/pages/balanceRecharge/balanceRecharge'
					} else if (record.data.id == 14) {
						// 群分享
						this.ruleForm.PagePath = 'pageA/pages/groupShare/groupShare'
					}else if (record.data.id == 15) {
          	// 全部信息页
						this.ruleForm.PagePath = 'pageA/pages/information/information'
					}else if (record.data.id == 16) {
						// 短视频带货
						this.ruleForm.PagePath = 'pageC/pages/shortVideos/record'
					}else if (record.data.id == 17) {
						// 积分码
						this.ruleForm.PagePath = 'pageA/pages/receive-point/receive-point'
					}else if (record.data.id == 18) {
						// 舌诊
						this.ruleForm.PagePath = 'pageC/pages/tongueDiagnosis/home'
					}else if (record.data.id == 19) {
						// 企店即创主页
						this.ruleForm.PagePath = 'pageC/pages/AIGC/home'
					}
				} else if (record.type == "product") {
					this.ruleForm.PageName = record.data.Name
					this.ruleForm.MenuType = 3
					this.ruleForm.PagePath = 'pages/detail/detail?id=' + record.data.Id
				} else if (record.type == "custom") {
					this.ruleForm.PageName = record.data.PageName
					this.ruleForm.MenuType = 4
					this.ruleForm.PagePath = 'pages/cusPage/cusPage?id=' + record.data.Id
				} else if (record.type == 'truntable') {
					this.ruleForm.PageName = record.data.Name
					this.ruleForm.MenuType = 6
					this.ruleForm.PagePath = 'pageA/pages/activity/truntable/truntable?id=' + record.data.Id
				} else if (record.type == 'live') {
					this.ruleForm.PageName = record.data.RoomName
					this.ruleForm.MenuType = 5
					this.ruleForm.OtherData = record
					this.ruleForm.PagePath = 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?room_id=' + record.data.RoomId
				} else if (record.type == 'coupon') {
					this.ruleForm.PageName = record.data.CouponName
					this.ruleForm.MenuType = 7
					this.ruleForm.OtherData = record
					this.ruleForm.PagePath = 'pages/couponCenter/receive/receive?id=' + record.data.Id + '&uid=' + (record.data.uid ||
						'')
				} else if (record.type == 'help') {
					this.ruleForm.PageName = record.data.Name
					this.ruleForm.MenuType = 8
					this.ruleForm.OtherData = record
					this.ruleForm.PagePath = 'pages/helpDetail/helpDetail?id=' + record.data.Id + '&activityId=' +
						record.data.ActivityFullId
				} else if (record.type == 'group') {
					this.ruleForm.PageName = record.data.ProductName
					this.ruleForm.MenuType = 9
					this.ruleForm.OtherData = record
					this.ruleForm.PagePath = 'pages/groupDetail/groupDetail?id=' + record.data.ProductId +
						'&fullId=' + record.data.ActivityFullId
				}

				this.ruleForm.CustomerServiceReplyContentId = null
				this.ruleForm.CommunityGroupCodeTitle = ''
			},
			async getWxReplyList(id) {
				try {
					this.loading = true
					let result = await apiList.communityGroupCodeList()
					this.wxReplyList = result.Result
					this.selectedReply.id = id
				} catch (e) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			handleCommand(e) {
				this.ruleForm.LinkType = e
				// this.editData = record;
				if (e == '0') {
					this.visibleable = true;
				} else {
					this.wxReplyVisible = true
					this.selectedReply = {
						id: null,
						title: '',
					}
					this.getWxReplyList(this.ruleForm.CustomerServiceReplyContentId)
				}
			},
			deleteImage() {
				this.ruleForm.NavigationImgUrl = ''
			},
			handleImgUrlSuccess(res, file) {
				const isLt5M = file.size / 1024 / 1024 > 5;
				if (isLt5M) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '上传图片大小不能超过5M!'
					})
					return false;
				}
				this.is_hover = false
				this.ruleForm.NavigationImgUrl = res[0]
				this.$forceUpdate();
			},
			handleAvatarSuccess(res, file) {
				this.ruleForm.ActivityPageHeadImgUrl = res[0];
				this.$forceUpdate();
			},
			descInput() {
				this.txtVal = this.ruleForm.ActivityRule.length;
			},
			// 保存
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.conflictGoodsList = []
						try {
							this.saveLoading = true
							if (this.ruleForm.StartTime == this.ruleForm.EndTime) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请设置正确的时间'
								})
								return
							}
							if (this.ruleForm.StartTime > this.ruleForm.EndTime) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请设置正确的时间'
								})
								return
							}
							if (!this.ruleForm.ProductList.length) {
								this.$common.completeFormInformation('商品至少选择一件', 'is-error-button')
								return
							}
							// this.ruleForm.ProductList.map(item => {
							// 	item.Id = item.ProductId
							// 	return item
							// })
							let data = {
								Name: this.ruleForm.Name,
								StartTime: this.ruleForm.StartTime,
								EndTime: this.ruleForm.EndTime,
								ActivityFlag: this.ruleForm.ActivityFlag,
								HelpNumber: this.ruleForm.HelpNumber,
								HelpEffectiveTime: this.ruleForm.HelpEffectiveTime,
								ReceiveEffectiveTime: this.ruleForm.ReceiveEffectiveTime,
								LimitType: this.ruleForm.LimitType,
								Quota: this.ruleForm.Quota,
								ActivityLimitType: this.ruleForm.ActivityLimitType,
								ActivityQuota: this.ruleForm.ActivityQuota,
								ProductList: this.ruleForm.ProductList,
								ActivityRule: this.ruleForm.ActivityRule,
								ActivityPageHeadImgUrl: this.ruleForm.ActivityPageHeadImgUrl,
								NavigationImgUrl: this.ruleForm.NavigationImgUrl,
								LinkType: this.ruleForm.LinkType,
								MenuType: this.ruleForm.MenuType,
								PageName: this.ruleForm.PageName,
								PagePath: this.ruleForm.PagePath,
								OtherData: this.ruleForm.OtherData,
								CustomerServiceReplyContentId: this.ruleForm.CustomerServiceReplyContentId,
								CommunityGroupCodeTitle: this.ruleForm.CommunityGroupCodeTitle,
								HelpLimitNumber: this.ruleForm.HelpLimitNumber
							}

							// console.log(this.ruleForm,'选择的东西')
							if (this.$route.query.type == 1 && this.$route.query.Id > 0) {
								data.Id = this.$route.query.Id
							} else {
								data.Id = 0
							}

							let result = await activityHelpReceiveSave(data)
							if (result.Result && result.Result.length) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '操作失败'
								});
								this.conflictGoodsList = result.Result
								this.conflictGoodsShow = true
							} else {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								if(this.$route.query.Id==0||this.$route.query.type==2){
									this.showtype = {
										issuccess:true,
										suctype:7,
										id:result.Result.Id,
									}
								}else{
									this.$router.push({
										path: '/market/Assistoreceive/Assistindex'
									});
								}
							}

						} catch (e) {} finally {
							this.saveLoading = false
						}

					} else {
						this.$common.completeFormInformation('请完善活动信息', 'is-error')
						return false;
					}
				});

			},
			// 取消
			canselEdit() {
				this.$router.push({
					path: '/market/Assistoreceive/Assistindex'
				});
			},
			handleSizeChange(val) {
				this.proPage.size = val;
				this.paginationPro()
			},
			handleCurrentChange(val) {
				this.proPage.current = val;
				this.paginationPro()
			},
			// 初始化数据
			async getInformation() {
				try {
					this.detailLoading = true;
					if (this.$route.query.Id > 0) {
						let data = {
							Id: this.$route.query.Id
						}
						let result = await activityHelpReceiveInfo(data)
						this.ruleForm = result.Result

						console.log(this.ruleForm.LinkType, '编辑的时候')
						// result.Result.ProductList.map(item => {
						// 	item.ProductId = item.Id
						// 	return item
						// })
						this.ruleForm.ActivityPageHeadImgUrl = (!this.ruleForm.ActivityPageHeadImgUrl) ?
							'/activityHeadimg.png' : this.ruleForm.ActivityPageHeadImgUrl

						this.ruleForm.ProductList = this.selectedFilterList = result.Result.ProductList
						if (this.$route.query.type == 2) {
							this.ruleForm.StartTime = ''
							this.ruleForm.EndTime = ''
						}
						this.paginationPro()
					}

				} catch (e) {

				} finally {
					this.detailLoading = false
				}

			},
			// 删除指定商品
			deleteSortPro(val, index) {
				this.ruleForm.ProductList = this.ruleForm.ProductList.filter(t => {
					if (t.Id != val.Id) {
						return t
					}
				})
				this.selectedFilterList = this.selectedFilterList.filter(t => {
					if (t.Id != val.Id) {
						return t
					}
				})
				if ((this.selectedFilterList.length) % this.proPage.size == 0 && this.proPage.current > 1) {
					this.proPage.current = this.proPage.current - 1;
				}
				this.paginationPro()
			},
			//商品分页
			paginationPro() {
				this.proPage.total = this.selectedFilterList.length;
				let start = (this.proPage.current - 1) * this.proPage.size
				let end = start + this.proPage.size
				let pagination = this.selectedFilterList.slice(start, end);
				this.paginationProduct = pagination
				this.$forceUpdate()
			},
			//获取选择的商品的列表
			getSelectList(data) {
				this.productSearchKey = ''
				this.ruleForm.ProductList = this.selectedFilterList = data
				this.selectProDialog = false
				this.handleFilterSelectes()
			},
			productRowKeys(row) {
				return row.Id
			},
			handleFilterSelectes() {
				this.proPage.current = 1
				this.selectedFilterList = []
				this.ruleForm.ProductList.filter(t => {
					if ((t.Name.indexOf(this.productSearchKey) != -1) || (t.ProductNo && (t.ProductNo.indexOf(this.productSearchKey) !=
							-1))) {
						this.selectedFilterList.push(t)
					}
				})
				this.paginationPro()
			},
			//选择商品
			handleSelectProDialog() {
				if ((this.ruleForm.StartTime == '' || this.ruleForm.StartTime == null) || (this.ruleForm.EndTime == '' || this.ruleForm
						.EndTime == null)) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请先完善活动时间'
					})
				} else {
					this.couponData.id = this.$route.query.type == 2 ? 0 : this.ruleForm.Id
					this.couponData.StartTime = this.ruleForm.StartTime
					this.couponData.EndTime = this.ruleForm.EndTime
					this.selectProDialog = true
				}
				// this.couponData.id = this.$route.query.type == 2 ? 0 : this.ruleForm.Id
				// this.selectProDialog = true
			},
			//限制输入框只能输入整数
			onlyclustNum(obj, index) {
				// index 1 助力人数 2 助力有效期

				obj = obj.replace(/[^\.\d]/g, '');
				obj = obj.replace('.', '');
				if (index == 1) {
					this.ruleForm.HelpNumber = obj
				} else if (index == 2) {
					this.ruleForm.HelpEffectiveTime = obj
				} else if (index == 3) {
					this.ruleForm.ReceiveEffectiveTime = obj
				} else {
					this.ruleForm.HelpLimitNumber = obj
				}
			},
		},
		computed: {
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
		},
	}
</script>

<style lang="less" scoped>
	.editgiftdetails {
		padding: 10px;
		background: #fff;

		.remarks {
			color: #909399;
			font-size: 14px;
		}

		.link-box {
			display: flex;
			flex-direction: row;

			.link-content {
				.page-button-error {
					color: #409EFF;
					font-size: 14px;
					cursor: pointer;
				}

				.link-btn {
					margin-left: 10px;
					color: #409EFF;
					font-size: 14px;
					cursor: pointer;
				}

				.link-btn:after {
					content: '';
					display: inline-block;
					width: 8px;
					height: 8px;
					border-top: 1px solid #409EFF;
					border-right: 1px solid #409EFF;
					transform: rotate(135deg);
					-webkit-transform: rotate(135deg);
					margin-left: 5px;
					margin-bottom: 3px;
				}

			}
		}

		.reply-dialog {
			.reply-list {
				max-height: 550px;
				overflow: auto;
				padding-right: 20px;

				.item {
					.label {
						font-weight: bold;
					}

					display: flex;
					font-size: 14px;

					.item-title {
						font-weight: bold;
						margin-bottom: 10px;
						font-size: 17px;
					}

					.item-text {}

					.item-img {
						.reply-img-list {
							display: flex;

							.img {
								width: 100px;
								height: 100px;
								margin-right: 20px;
								margin-bottom: 10px;
							}
						}
					}

					margin-bottom: 20px;
				}

			}

			.dialog-footer {
				text-align: center;
			}
		}

		.img-content {
			width: 752px;
			height: 152px;
			overflow: hidden;
			margin: -15px auto 20px 0px;

			.avatar-uploader {
				width: 750px;
				height: 150px;
			}

			::v-deep .el-form-item__content {
				margin-left: 70px !important;
			}

			.upload-avatar {
				width: 750px;
				height: 150px;
				object-fit: contain;
			}

			::v-deep .el-upload--picture-card {
				width: 752px !important;
				height: 152px !important;
			}

			.mask {
				width: 752px;
				height: 152px;
				border-radius: 6px;
				background-color: rgba(0, 0, 0, 0.7);
				position: absolute;

				.bin {
					color: #FFFFFF;
					font-size: 20px;
					position: absolute;
					left: 50%;
					margin-left: -10px;
					top: 50%;
					margin-top: -10px;
				}
			}
		}



		.specialErrorTable {
			::v-deep .el-form-item__error {
				width: auto !important;
				padding-top: 0px !important;
				position: initial !important;
				margin-top: 8px !important;
			}
		}

		::v-deep .el-form-item__error {
			width: 206px !important;
			padding-top: 9px;
		}

		.el-form-date-pick {
			display: inline-block;
		}

		.towLines {
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.lookImgsWidth {
			width: 460px;
		}

		.dialog-name-content {
			display: flex;
			flex-direction: row;

			img {
				width: 60px;
				height: 60px;
				object-fit: contain;
				margin-right: 10px;
			}

			.name {
				width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			span {
				color: #E51C23;
				font-size: 12px;
			}
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		::v-deep .el-upload--picture-card {
			width: 300px !important;
			height: 150px !important;
		}

		// .number__input {
		// 	border: 1px solid #ccc;
		// }


	}
</style>
